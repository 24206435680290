<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import { StateList } from "@/scripts/countries/states.js";
import { computed } from "vue";

const props = defineProps({
  request: {
    type: Object,
    required: true,
  },
});

const stateName = computed(() => {
  const stateMatch = StateList.find(
    (state) => state.value === props.request.state
  );
  return stateMatch?.label ?? props.request.state;
});
</script>

<template>
  <div class="data-remove-submitting">
    <inlineSvg name="data-delete/send" class="data-remove-submitting__icon" />
    <h1 class="data-remove__title">Finalizing your submission...</h1>
    <p class="data-remove__text">
      Cloaked is finalizing all the details for your data removal submission.
    </p>
    <p class="data-remove__text">
      Once our system has received your submission, it can take up to 2 weeks to
      completely remove all of your data.
    </p>
    <div class="data-remove-submitting__card">
      <div class="data-remove-submitting__card-content">
        <div
          class="data-remove-submitting__card-name"
          v-if="props.request.name"
        >
          {{ request.name }}
          <template v-if="props.request.age">
            , {{ props.request.age }}
          </template>
        </div>
        <div class="data-remove-submitting__card-state" v-if="stateName">
          {{ stateName }}
        </div>
      </div>
      <inlineSvg
        name="data-delete/spinner-delete"
        class="data-remove-submitting__card-icon"
      />
    </div>
  </div>
</template>

<style lang="scss">
.data-remove-submitting {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 548px;

  @media all and (min-width: $screen-lg) {
    padding: 64px;
    height: auto;
  }

  &__icon {
    color: $color-brand-3-100;
    width: 44px;
    height: 44px;
    opacity: 0;
    animation: appear-bottom-5 0.5s forwards ease-out;
  }

  .data-remove__title,
  .data-remove__text {
    opacity: 0;
    text-align: center;
    margin-top: 4px;
    animation: appear-bottom-5 0.5s forwards ease-out;
    margin-bottom: 16px;
  }

  .data-remove__title {
    animation-delay: 0.1s;
  }

  .data-remove__text {
    animation-delay: 0.15s;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 16px;
    border: 1px solid $color-primary-20;
    background: rgba(255, 255, 255, 0.1);
    background-blend-mode: screen;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-top: 16px;
    opacity: 0;
    animation: appear-bottom-5 0.5s forwards ease-out;
    animation-delay: 0.2s;

    @media all and (min-width: $screen-lg) {
      margin-top: 24px;
    }

    &-name {
      text-transform: capitalize;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-100;
    }

    &-state {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
      color: $color-primary-100;
    }

    &-icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      color: $color-primary-100;
    }
  }
}
</style>
