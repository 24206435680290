<script setup>
import OnboardingPageLogo from "@/components/feature/onboarding/page/OnboardingPageLogo.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import { useDataDeleteOverlay } from "@/routes/DataDeletion/useDataDeleteOverlay";
import { logout } from "@/scripts/actions/auth";
import { computed } from "vue";
import { isMobileDevice } from "@/scripts/regex";
import store from "@/store";

defineProps({
  status: {
    type: String,
    required: true,
  },
});

const { closeDataDeleteOverlay } = useDataDeleteOverlay();

const isMobile = computed(
  () => isMobileDevice && store.getters["screen/isSmallScreen"]
);
</script>

<template>
  <header class="data-remove-header">
    <OnboardingPageLogo class="data-remove-header__logo" />
    <button
      class="data-remove-header__button"
      @click="logout()"
      v-if="isMobile"
    >
      Sign out
    </button>
    <button
      v-else-if="!['submitting', 'submitted'].includes(status)"
      class="data-remove-header__close"
      @click="closeDataDeleteOverlay"
    >
      <InlineSvg name="modal-x" class="data-remove-header__icon" />
    </button>
  </header>
</template>

<style lang="scss">
.data-remove-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
  border-bottom: 1px solid $color-primary-10;

  &__logo {
    width: 137px;
    color: $color-primary-100;
  }

  &__icon {
    width: 16px;
  }

  &__button {
    color: $color-primary-100;
    font-family: $poppins;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border: 1px solid $color-primary-100;
    border-radius: 100px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__close {
    color: $color-primary-100;
    font-family: $poppins;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}
</style>
