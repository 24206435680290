<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import Button from "@/components/Button.vue";
import { useDataDeleteOverlay } from "@/routes/DataDeletion/useDataDeleteOverlay";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["submit"]);

const { openDataDeleteOverlay } = useDataDeleteOverlay();
</script>
<template>
  <div class="footer">
    <slot />
    <Button
      @click="emit('submit')"
      type="primary"
      size="2xl"
      :disabled="disabled"
      class="footer__button"
    >
      Check for exposures
      <InlineSvg name="arrow-right" />
    </Button>
    <Button
      type="secondary"
      size="2xl"
      @click="openDataDeleteOverlay"
      class="footer__button"
    >
      Remove data
    </Button>
  </div>
</template>
<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-primary-100;
  gap: 8px;
  margin-top: 24px;

  &__button {
    flex-shrink: 0;
  }
}
</style>
